import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/action";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

function Describe() {
  // const BASE_URL = "http://localhost:3800";

  const BASE_URL = "https://nft-ecom-backend.vercel.app";

  const cartItems = useSelector((state) => state.cart.cartItems);

  const { productId } = useParams();
  const dispatch = useDispatch();

  const handleAddToCart = (
    productId,
    price,
    productName,
    size,
    number,
    color,
    productDisplayImage,
    sku,
    tokenId
  ) => {
    dispatch(
      addToCart({
        productId,
        price,
        productName,
        size,
        number,
        color,
        productDisplayImage,
        sku,
        tokenId,
      })
    );
  };

  const [productDetails, setProductDetails] = useState("");
  const [sizes, setSizes] = useState([]);
  const [availableNumbers, setAvailableNumbers] = useState([]);

  const isPresentInCart = (size, number, productId) => {
    for (let i = 0; i < cartItems.length; i++) {
      if (
        cartItems[i].size === size &&
        cartItems[i].number === number &&
        productId === cartItems[i].productId
      ) {
        return true;
      }
    }

    return false;
  };

  async function fetchData() {
    try {
      const Url = BASE_URL + `/product/${productId}`;

      const response = await axios.get(Url);
      const value = response.data.data;

      const sizes = [];
      value.sizeQuantityMapping.forEach((item) => {
        sizes.push({
          name: item.size,
          inStock: item.quantity - item.quantitySold.length > 0,
        });
      });

      const numberSelectionObj = {};
      value.sizeQuantityMapping.forEach((item) => {
        let arr = [];
        for (let i = 1; i <= item.quantity; i++) {
          arr.push({
            name: i,
            inStock:
              !item.quantitySold.includes(i) &&
              !isPresentInCart(item.size, i, value._id),
          });
        }
        numberSelectionObj[item.size] = arr;
      });

      setAvailableNumbers(numberSelectionObj);

      setSizes(sizes);
      setProductDetails(value);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [cartItems]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [selectedSize, setSelectedSize] = useState();
  const [selectedNumber, setSelectedNumber] = useState();

  return (
    <div className="bg-white pt-10">
      <div className="pt-20">
        <nav aria-label="Breadcrumb">
          <ol className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <li>
              <div className="flex items-center">
                <div className="mr-2 text-sm font-medium text-gray-900">
                  {productDetails.category}
                </div>
                <svg
                  width={16}
                  height={20}
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="h-5 w-4 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                </svg>
              </div>
            </li>

            <li className="text-sm">
              <div
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                {productDetails.productName}
              </div>
            </li>
          </ol>
        </nav>

        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 overflow-hidden rounded-lg ">
            {productDetails?.productDescriptionImage && (
              <img
                src={productDetails?.productDescriptionImage[0]}
                alt="product-img-1"
                className="h-full w-full object-cover object-center"
              />
            )}
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              {productDetails?.productDescriptionImage && (
                <img
                  src={productDetails?.productDescriptionImage[1]}
                  alt="product-img-2"
                  className="h-full w-full object-cover object-center"
                />
              )}
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              {productDetails?.productDescriptionImage && (
                <img
                  src={productDetails?.productDescriptionImage[2]}
                  alt="product-img-2"
                  className="h-full w-full object-cover object-center"
                />
              )}
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            {productDetails?.productDescriptionImage && (
              <img
                src={productDetails?.productDescriptionImage[3]}
                alt="product-img-2"
                className="h-full w-full object-cover object-center"
              />
            )}
          </div>
        </div>

        {/* productDetails info */}
        <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {productDetails.productName}
            </h1>
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">productDetails information</h2>
            <p className="text-3xl tracking-tight text-gray-900">
              ${productDetails.price}
            </p>

            {/* Reviews */}
            {/* <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating
                          ? "text-gray-900"
                          : "text-gray-200",
                        "h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a
                  href={reviews.href}
                  className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div> */}

            {/* Sizes */}
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900">
                  Choose a size
                </h3>
              </div>

              <RadioGroup
                value={selectedSize}
                onChange={setSelectedSize}
                className="mt-4"
              >
                <RadioGroup.Label className="sr-only">
                  Choose a size
                </RadioGroup.Label>
                <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                  {sizes.map((size) => (
                    <RadioGroup.Option
                      key={size.name}
                      value={size}
                      disabled={!size.inStock}
                      className={({ active }) =>
                        classNames(
                          size.inStock
                            ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                            : "cursor-not-allowed bg-gray-50 text-gray-200",
                          active ? "ring-2 ring-amber-400" : "",
                          "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <RadioGroup.Label as="span">
                            {size.name}
                          </RadioGroup.Label>
                          {size.inStock ? (
                            <span
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-amber-400"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-md"
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <span
                              aria-hidden="true"
                              className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                            >
                              <svg
                                className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                                stroke="currentColor"
                              >
                                <line
                                  x1={0}
                                  y1={100}
                                  x2={100}
                                  y2={0}
                                  vectorEffect="non-scaling-stroke"
                                />
                              </svg>
                            </span>
                          )}
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {selectedSize && (
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">
                    Choose a numbers
                  </h3>
                </div>

                <RadioGroup
                  value={selectedNumber}
                  onChange={setSelectedNumber}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a number
                  </RadioGroup.Label>
                  <div className="grid grid-cols-5 gap-4 sm:grid-cols-8 lg:grid-cols-5">
                    {availableNumbers[selectedSize.name].map((size) => (
                      <RadioGroup.Option
                        key={size.name}
                        value={size}
                        disabled={!size.inStock}
                        className={({ active }) =>
                          classNames(
                            size.inStock
                              ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                              : "cursor-not-allowed bg-gray-50 text-gray-200",
                            active ? "ring-2 ring-amber-400" : "",
                            "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              {size.name}
                            </RadioGroup.Label>
                            {size.inStock ? (
                              <span
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-amber-400"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                              >
                                <svg
                                  className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line
                                    x1={0}
                                    y1={100}
                                    x2={100}
                                    y2={0}
                                    vectorEffect="non-scaling-stroke"
                                  />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            )}

            <button
              className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-amber-400 px-8 py-3 text-base font-medium text-black hover:bg-amber-400/75 focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2"
              onClick={(e) => {
                if (!selectedSize) {
                  toast("Please select a size", {
                    toastId: "error-select-size",
                  });
                  return;
                }

                if (!selectedNumber) {
                  toast("Please select a number", {
                    toastId: "error-select-number",
                  });
                  return;
                }

                handleAddToCart(
                  productId,
                  productDetails.price,
                  productDetails.productName,
                  selectedSize.name,
                  selectedNumber.name,
                  productDetails.color,
                  productDetails.productDisplayImage,
                  productDetails.sku,
                  productDetails.tokenId
                );
                toast("Item Added Successfully!", {
                  toastId: "add-item-to-card-id",
                });
              }}
            >
              Add to bag
            </button>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6 text-left">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                {productDetails?.productDescription
                  ?.split("\n")
                  .map((text, idx) => (
                    <p className="text-base text-gray-900" key={idx}>
                      {text}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Describe;
