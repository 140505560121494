import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from "./action";

const initialState = {
  cartItems: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const {
        productId,
        price,
        productName,
        size,
        number,
        color,
        productDisplayImage,
        sku,
        tokenId,
      } = action.payload;
      const existingItem = state.cartItems.find(
        (item) =>
          item.productId === productId &&
          item.size === size &&
          item.number === number
      );

      if (existingItem) {
        // const updatedItems = state.cartItems.map((item) =>
        //   item.productId === productId && item.size === size
        //     ? { ...item, quantity: item.quantity + 1 }
        //     : item
        // );

        const updatedItems = state.cartItems.map((item) =>
          item.productId === productId && item.size === size ? item : item
        );

        localStorage.setItem("default-user-cart", JSON.stringify(updatedItems));

        return {
          ...state,
          cartItems: updatedItems,
        };
      } else {
        const updatedItems = [
          ...state.cartItems,
          {
            productId,
            quantity: 1,
            price,
            productName,
            size,
            number,
            color,
            productDisplayImage,
            sku,
            tokenId,
          },
        ];

        localStorage.setItem("default-user-cart", JSON.stringify(updatedItems));

        return {
          ...state,
          cartItems: updatedItems,
        };
      }

    case REMOVE_FROM_CART:
      const updatedItems = state.cartItems.filter(
        (item) =>
          !(
            item.productId === action.payload.productId &&
            item.size === action.payload.size &&
            item.number === action.payload.number
          )
      );

      localStorage.setItem("default-user-cart", JSON.stringify(updatedItems));

      return {
        ...state,
        cartItems: updatedItems,
      };

    case CLEAR_CART:
      localStorage.setItem("default-user-cart", JSON.stringify([]));

      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};

export default cartReducer;
