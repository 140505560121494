import React from "react";
import Product from "./Product";

function Ecom() {
  return (
    <div>
      <Product />
    </div>
  );
}

export default Ecom;
