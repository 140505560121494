export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

export const addToCart = ({
  productId,
  productName,
  price,
  size,
  number,
  color,
  productDisplayImage,
  sku,
  tokenId,
}) => {
  return {
    type: ADD_TO_CART,
    payload: {
      productId,
      productName,
      price,
      size,
      number,
      color,
      productDisplayImage,
      sku,
      tokenId,
    },
  };
};

export const removeFromCart = (productId, size, number) => {
  return {
    type: REMOVE_FROM_CART,
    payload: {
      productId,
      size,
      number,
    },
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};
